import React from "react"
import { Button, Modal } from "react-bootstrap"

type Props = {
  signOut: any;
  showModal: boolean;
  onHideModal: () => void;
}

const SignoutConfirmModal = React.memo(({ signOut, showModal, onHideModal }: Props) => {
  return (
    <Modal show={showModal} onHide={onHideModal}>
      <Modal.Header closeButton>
        <Modal.Title>サインアウトしますか？</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHideModal}>キャンセル</Button>
        <Button variant="primary" onClick={signOut}>サインアウトする</Button>
      </Modal.Footer>
    </Modal>
  )
})

export { SignoutConfirmModal }
