import React, { useCallback } from "react"
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap"
import { api } from "../lib/api";

type AddTaskFormProps = {
  onSend: () => void
}

const AddTaskForm = React.memo(({ onSend }: AddTaskFormProps) => {
  const taskNameRef = React.createRef<HTMLInputElement>();
  const deadlineDateRef = React.createRef<HTMLInputElement>();

  type PayloadOfPostTaskApi = {
    task_name: string;
    deadline_date: string | null;
  }
  const onClickAddButton = useCallback(async () => {
    console.time("onClickAddButton");
    if (!taskNameRef.current) {
      return
    }

    let payload: PayloadOfPostTaskApi = {
      task_name: taskNameRef.current.value,
      deadline_date: deadlineDateRef.current?.value || null
    }
    console.log("[ADD]", payload);
    await api.post("task/", payload);

    onSend();
    console.timeEnd("onClickAddButton");
  }, [taskNameRef, deadlineDateRef, onSend])

  return (
    <Form className="add-task-form">
      <Row>
        <Col xs="auto">
          <Form.Label visuallyHidden>タスク名</Form.Label>
          <Form.Control ref={taskNameRef} type="text" placeholder="やること・買うものなど" required />
        </Col>
        <Col xs="auto">
          <Form.Label visuallyHidden>期日</Form.Label>
          <InputGroup>
            <InputGroup.Text>〆</InputGroup.Text>
            <Form.Control ref={deadlineDateRef} type="date" />
          </InputGroup>
        </Col>
        <Col xs="auto">
          <Button type="submit" variant="secondary" onClick={onClickAddButton}>追加</Button>
        </Col>
      </Row>
    </Form>
  )
})

export { AddTaskForm }
